<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <i class="wizard-icon flaticon-globe"></i>
                <h3 class="wizard-title">1. Enter Details</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-responsive"></i>
                <h3 class="wizard-title">2. Select Services</h3>
              </div>
              <!-- <i class="wizard-arrow flaticon2-next"></i> -->
            </div>

            <!-- <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-list"></i>
                <h3 class="wizard-title">3. Review and Submit</h3>
              </div>
              <i class="wizard-arrow last flaticon2-next"></i>
            </div> -->
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-7">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ $t("networkDeviceWizard.step1.title") }}
                </h4>

                <div class="form-group">
                  <label>Wifi Name (SSID)</label>
                  <b-form-input
                    id="ssidName"
                    type="text"
                    class="form-control form-control-solid form-control-lg"
                    placeholder="Wifi Name"
                    v-model="ssidName"
                    :state="ssidNameValidation"
                    aria-describedby="wifi-name-feedback"
                    trim
                  />
                  <b-form-invalid-feedback id="wifi-name-feedback">
                    Enter at least 5 letters
                  </b-form-invalid-feedback>
                  <span class="form-text text-muted"
                    >The SSID - Wifi name"</span
                  >
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ $t("networkDeviceWizard.step2.title") }}
                </h4>

                <!--begin::Table-->
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-vertical-center"
                    id="kt_advance_table_widget_1"
                  >
                    <thead>
                      <tr class="text-left">
                        <th class="pl-0" style="width: 20px">
                          <label class="checkbox checkbox-lg checkbox-single">
                            <input
                              type="checkbox"
                              @input="setCheck($event.target.checked)"
                            />
                            <span></span>
                          </label>
                        </th>
                        <th class="pr-0" style="width: auto">
                          {{ $t("networkDeviceWizard.step2.ssid") }}
                        </th>
                        <th style="min-width: auto">
                          {{ $t("networkDeviceWizard.step2.device") }}
                        </th>
						<th style="min-width: auto">
                          {{ $t("networkDeviceWizard.step2.object") }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <template v-for="(item, itemIxn) in available_devices">
						  <tr v-bind:key="itemIxn">
							  <td class="pl-0">
								<label class="checkbox checkbox-lg checkbox-single">
									<input type="checkbox" 
										v-bind:value="item.id" 
										:checked="checked" 
										@change="checkSelectedDevice($event, item)"
										/>
									<span></span>
								</label>
							</td>

							<td class="pl-0">
								{{ item.ssid }}
							</td>

							<td class="pl-0">
								{{ item.description }}
							</td>

							<td class="pl-0">
								{{ item.object }}
							</td>
						  </tr>

					
                      </template>
					  <tr>
						  <td colspan="4" v-if="available_devices.length == 0">
							  {{ $t("networkDeviceWizard.step2.nodata") }}
						  </td>
					  </tr>
                    </tbody>
                  </table>
                </div>
                <!--end::Table-->
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    v-on:click="submit" 
                    v-if="selected_devices.length > 0"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Submit
                  </button>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    Next Step
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
// @import "@/assets/sass/pages/wizard/wizard-3.scss";
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_AVAILABLEDEVICES_BY_SSID, SET_OWNER_TO_DEVICES } from "@/core/services/store/devices.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import i18n from "@/core/plugins/vue-i18n";

export default {
  name: "Wizard-1",
  data() {
    return {
      global_wizard: {},
      available_devices: [],
      selected_devices: [],
      ssidName: "",
	  checked: false
    };
  },
  computed: {
    ssidNameValidation() {
      return this.ssidName.length > 5 ? true : false;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Wizard" },
      { title: "Wizard-1" }
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });

    this.global_wizard = wizard;

    // Validation before going to next page
    wizard.on("change", wizardObj => {
		// console.log(i18n.messages);
      // validate the form and use below function to stop the wizard's step
      if (wizardObj.getStep() == 1 && this.validateStepOne() == false) {
        wizardObj.stop();
        Swal.fire({
          title: "",
          text: i18n.messages.en.networkDeviceWizard.step1.ssidNetworkRequired,
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      } 
      // else if (wizardObj.getStep() == 2 && this.validateStepTwo() == false) {
      //   wizardObj.stop();
      //   Swal.fire({
      //     title: "",
      //     text: "Select device availability",
      //     icon: "error",
      //     confirmButtonClass: "btn btn-secondary"
      //   });
      // }
	  
	  if(wizardObj.getStep() == 1  && this.validateStepOne() == true) {
		this.getAvailableDevices();
	  }
    });

    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    validateStepOne: function() {
      return this.ssidName.length >= 5 ? true : false;
    },
    validateStepTwo: function() {
      return this.selected_devices.length > 0;
    },
	getAvailableDevices: function() {
		this.$store.dispatch(GET_AVAILABLEDEVICES_BY_SSID, "?ssid="+this.ssidName).then((devicesResp)=>{
			this.available_devices = devicesResp;
			});
	},
	setCheck(checked) {
      this.checked = checked;
    },
	checkSelectedDevice(e, item) {
		let ixn = this.selected_devices.findIndex(fnd => fnd.id === item.id);

		if(!e.target.checked && ixn >= 0) {
			this.selected_devices.splice(ixn, 1);
		}
		else if(e.target.checked && ixn === -1) {
			this.selected_devices.push({deviceId: item.id});
		}
	},
    submit: function(e) {
      e.preventDefault();
		// let data = JSON.stringify(this.selected_devices);

	  this.$store.dispatch(SET_OWNER_TO_DEVICES, {devices: this.selected_devices}).then((devicesResp)=>{
		  this.checked = false;
		  devicesResp;
		  this.getAvailableDevices();
			Swal.fire({
			title: "",
			text: "The application has been successfully submitted!",
			icon: "success",
			confirmButtonClass: "btn btn-secondary"
		});
		});
      
    }
  }
};
</script>
